import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../../components/layout";
import ContactFormComponent from "../../components/contactForm";

import { Grid, Typography, useScrollTrigger, Slide } from "@material-ui/core";
import "../../assets/css/h2.css";
import ReactMarkdown from "react-markdown";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    maxHeight: "min-content",
  },
  overflowHidden: {
    overflow: "hidden",
  },
  leftHeading: {
    textAlign: "left",
    padding: 5,
    whiteSpace: "nowrap",
    position: "absolute",
    left: -50,
    [theme.breakpoints.up("sm")]: {
      position: "relative",
      left: "unset",
    },
  },
  rightHeading: {
    textAlign: "right",
    padding: 5,
    whiteSpace: "nowrap",
    textAlign: "left",
    minHeight: 700,
  },
  centerBlock: {
    // order: -1,
    // [theme.breakpoints.up("md")]: {
    //   order: 0,
    // },
  },
}));

const FeedbackPage = () => {
  const classes = useStyles();
  const data = useStaticQuery(query);
  const { strapiFeedbackSupport } = data;
  console.log(data);

  const {
    heading,
    headingLeft,
    headingRight,
    ContactForm,
    description,
  } = strapiFeedbackSupport;

  return (
    <Layout>
      <Grid container>
        <Grid item xs={2} sm={3} className={classes.overflowHidden}>
          <Typography className={classes.leftHeading} variant="h2">
            {headingLeft}
          </Typography>
        </Grid>
        <Grid item xs={8} sm={6} className={classes.centerBlock}>
          <Typography variant="h3">{heading}</Typography>
          <p>{description}</p>
          <ContactFormComponent data={ContactForm} />
        </Grid>
        <Grid item sm={3} xs={2} className={classes.overflowHidden}>
          <Typography className={classes.rightHeading} variant="h2">
            {headingRight}
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  );
};

const query = graphql`
  query {
    strapiFeedbackSupport {
      heading
      description
      headingLeft
      headingRight
      ContactForm {
        emailPlaceholder
        feedbackPlaceholder
        id
        messagePlaceholder
        submitButtonText
        successMessage
      }
    }
  }
`;

export default FeedbackPage;
