import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import { Input, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    margin: "1rem 0",
    width: "100%",
  },
}));

const ContactForm = (props) => {
  const {
    emailPlaceholder,
    messagePlaceholder,
    submitButtonText,
    successMessage,
  } = props.data;

  const [state, handleSubmit] = useForm("xyylpgwa");

  if (state.succeeded) {
    return <p>{successMessage}</p>;
  }

  const classes = useStyles();
  return (
    <form onSubmit={handleSubmit}>
      <TextField
        type="email"
        name="email"
        label={emailPlaceholder}
        // placeholder={emailPlaceholder}
        variant="outlined"
        className={classes.input}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <ValidationError prefix="Email" field="email" errors={state.errors} />
      <TextField
        id="message"
        name="message"
        label={messagePlaceholder}
        // placeholder={messagePlaceholder}
        multiline
        rows={4}
        variant="outlined"
        className={classes.input}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <ValidationError prefix="Message" field="message" errors={state.errors} />
      <Button
        type="submit"
        // disabled={state.submitting}
        variant="contained"
        color="secondary"
        className={classes.input}
      >
        {submitButtonText}
      </Button>
    </form>
  );
};

export default ContactForm;
